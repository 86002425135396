import React, { Fragment, lazy, Suspense } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";

const LazyHomePage = lazy(() => import('./pages/Home'));
const LazyServicesPage = lazy(() => import('./pages/Services'));
const LazyProjectsPage = lazy(() => import('./pages/Projects'));
const LazyGalleryPage = lazy(() => import('./pages/Gallery'));
const LazyAboutUsPage = lazy(() => import('./pages/AboutUs'));
const LazyContactUsPage = lazy(() => import('./pages/ContactUs'));


const AppRoutesFunction = () => {
    return (
        <Fragment>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyHomePage key='home-page' />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/services"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyServicesPage key='services-page' />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/projects"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyProjectsPage key='projects-page' />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/gallery"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyGalleryPage key='gallery-page' />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/about-us"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyAboutUsPage key='about-page' />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/contact-us"
                    element={
                        <Suspense
                            fallback={
                                <div className='loader-container'>
                                    <div className="loader"></div>
                                </div>
                            }
                        >
                            <LazyContactUsPage key='contact-page' />
                        </Suspense>
                    }
                />
            </Routes>
        </Fragment>
    );
};

const AppRoutes = () => {
    return (
        <HashRouter>
            <AppRoutesFunction />
        </HashRouter>
    );
};

export default AppRoutes;